<template>
  <div class="row card_top" v-if="currentTasks.at(0)">
    <StatsAnimation></StatsAnimation>
    <!-- <a href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css"></a> -->

    <div class="col-xl-3">
      <div class="card staticscard">
        <div class="staticsbody mt-8">
          <div class="align-items-center justify-content-between card-spacer">
            <div id="totalrec" class="d-flex flex-column text-center">
              <span class="totalRecord-Title">Number of Files Uploaded</span>
              <span class="totalRecord-Count">{{
                currentTasks.at(0).totalfilecount.toLocaleString()
              }}</span>
              <!-- <span class="totalRecord-Count">{{
                currentTasks.filter((task) => task.stage !== "Archived").length
              }}</span> -->
              <!-- {{currentTasks.at(0).totalfilecount.toLocaleString()}} -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-3">
      <div class="card staticscard">
        <div class="staticsbody mt-8">
          <div class="align-items-center justify-content-between">
            <div id="totalrec" class="d-flex flex-column text-center">
              <span class="totalRecord-Title">Total Records Processed</span>
              <span class="totalRecord-Count">{{
                currentTasks.at(0).totalcleaned.toLocaleString()
              }}</span>
              <!-- <span class="totalRecord-Count">{{
                currentTasks.filter((task) => task.stage !== "Archived").totalcleaned.toLocaleString()
              }}</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-3">
      <div class="card staticscard">
        <div class="staticsbody mt-8">
          <div class="align-items-center justify-content-between">
            <div id="totalrecimp" class="d-flex flex-column text-center">
              <span class="totalRecord-Title">Total Records Improved</span>
              <span class="totalRecord-Count">{{
                currentTasks.at(0).totalimproved.toLocaleString()
              }}</span>
              <!-- <span class="totalRecord-Count">{{
                currentTasks.filter((task) => task.stage !== "Archived").totalimproved.toLocaleString()
              }}</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-3">
      <div class="card staticscard">
        <div class="staticsbody mt-8">
          <div class="align-items-center justify-content-between">
            <div id="totalemailadd" class="d-flex flex-column text-center">
              <span class="totalRecord-Title">Total Email Addresses</span>
              <span class="totalRecord-Count">{{
                currentTasks.at(0).totalemail.toLocaleString()
              }}</span>
              <!-- <span class="totalRecord-Count">{{
                currentTasks.filter((task) => task.stage !== "Archived").totalemail.toLocaleString()
              }}</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StatsAnimation from "/src/assets/js/components/StatsAnimation.js";

export default {
  StatsAnimation,
  name: "totalRecords",
  props: {
    currentTasks: Array,
  },
  data() {
    return {};
  },
  // computed: {
  //   ...mapGetters(["layoutConfig"])
  // },
  mounted() {},
  methods: {},
};
</script>

<style>
.totalRecord-Title {
  font-weight: 400;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #737676;
  margin-bottom: 10px;
}

.totalRecord-Count {
  font-weight: 600;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  color: #454646;
  letter-spacing: -0.408px;
}

.totalRecords-Summary {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #454646;
  opacity: 0.8;
}

.totalRecords-Summary-data {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #454646;
  opacity: 0.5;
}

.card_top {
  margin-top: 0px;
  margin: 0px 1px;
  /* background-color:hsl(0,100%,100%); */
}

.staticscard {
  border-radius: 20px;
  height: 116px;
  width: 100%;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
}

/* .staticsbody{
padding: 20px;
} */

/* .card.card-custom > .staticsbody{
  padding: 2.25rem;
} */
</style>
